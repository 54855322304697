import Layout from "../components/layout";
import {useContext} from "react"
import React from "react";
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';

import FirebaseContext from '../components/context/firebaseContext'
import UserContext from '../components/context/userContext'

const SignIn = (props) => 
    {
        var fbc = useContext(FirebaseContext)
        var {isSignedIn, user} = useContext(UserContext)
        return (
        <Layout>
            {(fbc.initialized === true)?
               ((isSignedIn)?
                 <div><span>Logged in user: </span><span>{user.displayName}</span> <span><button  onClick={(e)=>fbc.firebase.auth().signOut()}>Logout</button></span> </div>:
                 <StyledFirebaseAuth
                    uiConfig={fbc.uiConfig} 
                    firebaseAuth={fbc.firebase.auth()}/>)
             :<div>Firebasse not initialized</div>
            }
        </Layout>)
    }
export default SignIn